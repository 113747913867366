import { useFormikContext } from 'formik';
import { forEach, get } from 'lodash';
import React from 'react';

import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import {
    Autocomplete, Checkbox, Chip, MenuItem, TextField, Theme, Typography
} from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

const EllipsisText = (props) => {
  const { children } = props;
  return (
    <div
      style={{
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: '50px',
      }}
    >
      {children}
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    hoverWrapper: {
      '&:hover': {
        animation: 'none',
      },
    },
  })
);

const FanGoFormField = ({ type, id, label, options }) => {
  const classes = useStyles();
  const { values, handleChange } = useFormikContext();
  const labelFontSize = 17;
  const selectOptionFontSize = 12;
  const getValue = () => {
    const val = get(values, id);
    return val;
  };
  if (type === 'text') {
    return (
      <TextField
        id={id}
        inputProps={{
          style: { fontSize: '13px' },
        }}
        value={getValue()}
        onChange={handleChange}
        label={label}
        placeholder={label}
        variant="outlined"
        size="small"
        fullWidth
        InputLabelProps={{
          style: {
            fontSize: 15,
          },
        }}
      />
    );
  }
  if (type === 'select') {
    return (
      <TextField
        key={label}
        inputProps={{
          style: { fontSize: '15px' },
          MenuProps: { disableScrollLock: true },
        }}
        InputLabelProps={{
          style: {
            fontSize: labelFontSize,
          },
        }}
        SelectProps={{
          style: {
            fontSize: '12px',
          },
        }}
        sx={{
          // Increases the 'notch' for the textfield and stops it going through the label
          '& .MuiOutlinedInput-root': {
            fontSize: `${labelFontSize}px!important`,
          },
          '& .MuiSelect-select': { fontSize: '12px' },
        }}
        variant="outlined"
        size="small"
        fullWidth
        select
        label={label}
        onChange={(e) => {
          const { value } = e.target;
          handleChange({
            target: {
              id: id,
              value,
            },
          });
        }}
        value={getValue()}
      >
        {options.map((option) => {
          const { value, label, info } = option;
          if (info) {
            return (
              <MenuItem key={value} value={value} style={{ fontSize: 12 }}>
                {`${label} ${info}`}
              </MenuItem>
            );
          }
          return (
            <MenuItem key={value} value={value} style={{ fontSize: 12 }}>
              {label}
            </MenuItem>
          );
        })}
      </TextField>
    );
  }
  if (type === 'autoComplete') {
    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;

    const selectedOptions = get(values, id);

    const getValues = () => {
      return selectedOptions;
    };

    const onChange = (e, newValue) => {
      handleChange({
        target: {
          id,
          value: newValue,
        },
      });
    };

    return (
      <Autocomplete
        className={classes.hoverWrapper}
        ListboxProps={{ style: { maxHeight: '230px' } }}
        sx={{
          // Increases the 'notch' for the textfield and stops it going through the label
          '& .MuiOutlinedInput-root': {
            fontSize: `${labelFontSize}px!important`,
          },
          '& .MuiSelect-select': { fontSize: '12px' },
          '& .MuiFormLabel-root': {
            fontSize: '15px',
          },
        }}
        multiple
        renderTags={(value, getTagProps) => {
          const numTags = value.length;
          const limitTags = 1;

          return (
            <>
              {value.slice(0, limitTags).map((option, index) => (
                // make size on this to stop the screen being pushed off
                <Chip
                  {...getTagProps({ index })}
                  key={index}
                  label={<EllipsisText>{option.title}</EllipsisText>}
                />
              ))}

              {numTags > limitTags && ` +${numTags - limitTags}`}
            </>
          );
        }}
        size="small"
        limitTags={1}
        disableCloseOnSelect
        options={options}
        value={getValues()}
        onChange={onChange}
        getOptionLabel={(option) => {
          return option.title;
        }}
        isOptionEqualToValue={(option, value) => {
          return option.id === value.id;
        }}
        renderOption={(props, option, { selected }) => {
          const { key, ...optionProps } = props;
          return (
            <li key={key} {...optionProps}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              <p style={{ padding: 0, margin: 0, fontSize: '12px' }}>
                {option.title}
              </p>
            </li>
          );
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label={label}
            size="small"
          />
        )}
      />
    );
  }
};

export default FanGoFormField;
