import { useFormikContext } from 'formik';
import React, { useContext } from 'react';

import { Theme, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

import { MobileScreenContext } from '../../../../../providers/MobileScreenProvider';
import CustomButton from '../../../../common/button/CustomButton';
import FanGoFormField from '../../../../common/FanGoFormField';
import { diningSelectionSections, mobileNavigation } from '../utils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonWrapper: {
      display: 'flex',
      justifyContent: 'center',
    },
    inputOptionsContainer: {
      display: 'flex',
      rowGap: theme.spacing(2),
      flexDirection: 'column',
      paddingLeft: '5px',
    },
    sectionTitleText: {
      color: '#141854',
      fontWeight: 'bold',
      marginBottom: theme.spacing(1.5),
      fontSize: '14px',
    },
    allergensContainer: {
      paddingLeft: '5px',
      display: 'flex',
      flexDirection: 'column',
      margin: 0,
    },
    sectionContainer: {
      marginTop: 10,
      display: 'flex',
      flexDirection: 'column',
      rowGap: 10,
    },
    optionsContainer: {
      display: 'flex',
      flexDirection: 'column',
      rowGap: 10,
    },
  })
);

const DiningSelectionsScreenContents = () => {
  const classes = useStyles();
  const { values, handleChange } = useFormikContext();
  const { screen, setScreen, screenStack, setScreenStack, setNavigationType } =
    useContext(MobileScreenContext);

  return (
    <>
      <div className={classes.sectionContainer}>
        {diningSelectionSections.map((section) => {
          const { title, options, sectionId } = section;
          return (
            <div key={sectionId} className={classes.optionsContainer}>
              <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
                {title}
              </Typography>
              {options.map((option) => {
                const { type, optionId, label, inputOptions } = option;
                return (
                  <div key={optionId}>
                    <FanGoFormField
                      key={optionId}
                      type={type}
                      id={`[diningSelection][${sectionId}][${optionId}]`}
                      label={label}
                      options={inputOptions}
                    />
                  </div>
                );
              })}
            </div>
          );
        })}

        <div className={classes.buttonWrapper} style={{ marginTop: 5 }}>
          <CustomButton
            onClick={() =>
              mobileNavigation({
                type: 'allergenAndDietarySelections',
                screenStack,
                setScreen,
                screen,
                setNavigationType,
              })
            }
            buttonName="Add Allergen and Dietary Requirements"
          />
        </div>
        <div className={classes.buttonWrapper}>
          <CustomButton
            onClick={() =>
              mobileNavigation({
                type: 'diningSelectionSubmission',
                screenStack,
                setScreen,
                screen,
                setNavigationType,
              })
            }
            buttonName="Submit Dining Selections"
          />
          {/* <Button
            variant="outlined"
            onClick={() => {
              mobileNavigation({
                type: 'diningSelectionSubmission',
                screenStack,
                setScreen,
                screen,
                setNavigationType,
              });
            }}
            sx={{
              backgroundColor: '#141855',
              color: 'white',
              fontSize: '12px',
              paddingLeft: '5px',
              paddingRight: '5px',
            }}
          >
            Submit Dining Selections
          </Button> */}
        </div>
      </div>
    </>
  );
};

export default DiningSelectionsScreenContents;
