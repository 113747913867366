import { useFormikContext } from 'formik';
import React, { useContext, useEffect } from 'react';
import ConfettiExplosion from 'react-confetti-explosion';

import { Typography } from '@mui/material';

import { MobileScreenContext } from '../../../../../providers/MobileScreenProvider';
import getMobileScreen from '../getMobileScreen';
import { getInitialValues, mobileNavigation } from '../utils';

const DiningSelectionSubmission = () => {
  const { screen, setScreen, screenStack, setScreenStack, setNavigationType } =
    useContext(MobileScreenContext);
  const { handleChange } = useFormikContext();
  const initialValues = getInitialValues({ type: 'diningSelection' });

  useEffect(() => {
    handleChange({
      target: {
        id: 'diningSelection',
        value: initialValues.diningSelection,
      },
    });
    setTimeout(() => {
      const diningSelectionScreen = getMobileScreen({
        type: 'diningSelection',
      });
      setScreenStack([diningSelectionScreen]);
      mobileNavigation({
        type: 'diningSelection',
        screenStack,
        setScreen,
        screen,
        setNavigationType,
      });
    }, 5000);
  }, []);

  return (
    <>
      <div
        style={{
          textAlign: 'center',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          height: '90%',
          rowGap: 20,
        }}
      >
        <Typography variant="h6" color="secondary">
          Thank you for trying FanGo!
        </Typography>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <ConfettiExplosion width={320} />
        </div>
      </div>
    </>
  );
};

export default DiningSelectionSubmission;
