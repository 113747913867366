import BackgroundImage from 'gatsby-background-image';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { convertToBgImage } from 'gbimage-bridge';
import React from 'react';

import { Typography } from '@mui/material';

import FanGoBody from '../../../common/body/FanGoBody';
import { FanGoTextProcessor } from '../../../common/portableText';
import FanGoTitle from '../../../common/texts/FanGoTitle';
import MobileScreen from '../../CommonComps/deviceScreens/MobileScreen';

const HowToUseOurPreOrderingPlatform = ({ howToUseOurPreOrderSystem }) => {
  const { titleAndSubTitle, image, _rawParagraph } = howToUseOurPreOrderSystem;
  const {
    title: howToUseOurPreOrderSystemTitle,
    text: howToUseOurPreOrderSystemText,
  } = titleAndSubTitle;
  const imgData = getImage(image.asset.gatsbyImageData);
  const bgImage = convertToBgImage(imgData);

  const containerMinHeight = '450px';

  return (
    <BackgroundImage
      style={{
        minHeight: containerMinHeight,
        width: '100%',
        backgroundColor: 'white',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
      }}
      {...bgImage}
    >
      <div
        style={{
          backgroundColor: `rgba(0, 0, 0, 0.50`,
          minHeight: containerMinHeight,
        }}
      >
        <div
          style={{
            rowGap: 5,
            minHeight: containerMinHeight,
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            justifyContent: 'center',
            maxWidth: '70%',
            margin: 'auto',
          }}
        >
          <FanGoTitle title={howToUseOurPreOrderSystemTitle} color="white" />
          <Typography fontWeight={700} textAlign="center" color="white">
            {howToUseOurPreOrderSystemText}
          </Typography>

          <FanGoTextProcessor
            text={_rawParagraph}
            textAlign="center"
            color="white"
            fontWeight={700}
          />
        </div>
      </div>
    </BackgroundImage>
  );
};

export default HowToUseOurPreOrderingPlatform;
