import React from 'react';

import { useMediaQuery } from '@mui/material';

import FanGoBody from '../../../common/body/FanGoBody';
import FanGoTitle from '../../../common/texts/FanGoTitle';
import ReverseImageAndText from '../../CommonComps/ReverseImageAndText';

const EnsuringFoodSafety = ({ ensuringFoodSafety }) => {
  const { title, imagesAndParagraphs } = ensuringFoodSafety;
  const smallScreen = useMediaQuery(`(max-width: 900px)`);
  return (
    <FanGoBody paddingTop="10px">
      <FanGoTitle title={title} />
      <ReverseImageAndText
        imagesAndParagraphs={imagesAndParagraphs}
        smallScreen={smallScreen}
        imgGridItemSize={6}
        textGridItemSize={6}
        maxHeight={400}
        paddingLeft={4}
        paddingRight={4}
        showImageBackgroundEffect
        showTextShadows
      />
    </FanGoBody>
  );
};

export default EnsuringFoodSafety;
