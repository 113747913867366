import React from 'react';

import FanGoBody from '../../../common/body/FanGoBody';
import CustomGridCarousel from '../../../common/carousel/CustomGridCarousel';
import CustomFanGoCard from '../../../common/fanGoCard/CustomFanGoCard';
import { FanGoTextProcessor } from '../../../common/portableText';
import FanGoTitle from '../../../common/texts/FanGoTitle';

const responsiveSizes = [
  {
    breakpoint: 1000,
    cols: 3,
    rows: 2,
  },
  {
    breakpoint: 900,
    cols: 3,
    rows: 1,
  },
  {
    breakpoint: 650,
    cols: 2,
    rows: 1,
  },
  {
    breakpoint: 500,
    cols: 1,
    rows: 1,
  },
];

const BenefitsOfOurPreOrderSystem = ({
  benefitsOfOurPreOrderingSystem,
  benefitCards,
}) => {
  const {
    title: benefitsOfOurPreOrderingSystemTitle,
    _rawBody: benefitsOfOurPreOrderingSystemText,
  } = benefitsOfOurPreOrderingSystem;
  const { productFeatures: cards } = benefitCards;

  const cardsArr = [];
  cards.forEach((item, index) => {
    const { cardImage, _key, feature, description } = item;
    const { asset, altText } = cardImage;
    const image = asset?.gatsbyImageData;
    const card = (
      <CustomFanGoCard
        minHeight="600px"
        cardMaxWidth="400px"
        key={_key}
        title={feature}
        image={image}
        altText={altText}
        text={description}
      />
    );
    cardsArr.push(card);
  });
  return (
    <FanGoBody mobilePaddingBottom="60px">
      <FanGoTitle title={benefitsOfOurPreOrderingSystemTitle} />
      <FanGoTextProcessor
        text={benefitsOfOurPreOrderingSystemText}
        textAlign="center"
      />
      <div style={{ paddingTop: 8 }}>
        <CustomGridCarousel
          itemsArray={cardsArr}
          responsiveSizes={responsiveSizes}
          defaultCols={3}
          defaultRows={2}
          paddingBottom={2}
          speed={500}
        />
      </div>
    </FanGoBody>
  );
};

export default BenefitsOfOurPreOrderSystem;
