import { graphql } from 'gatsby';
import React from 'react';

import FanGoBody from '../components/common/body/FanGoBody';
import StandardSEO from '../components/common/seo/StandardSEO';
import { TrustedBrandIcons } from '../components/PageComps/CommonComps';
import BlogsPreview from '../components/PageComps/CommonComps/blogsPreview/BlogsPreview';
import FullImageBackgroundHeader from '../components/PageComps/CommonComps/heroSections/FullImageBackgroundHeader';
import BasicTitleSubTitle from '../components/PageComps/CommonComps/heroSections/heroSectionComps/BasicTitleSubTitle';
import AllergenAndDiningSelection from '../components/PageComps/PreOrderPageComps/RefactoredPreOrderComps/AllergenAndDiningSelection';
import BenefitsOfOurPreOrderSystem from '../components/PageComps/PreOrderPageComps/RefactoredPreOrderComps/BenefitsOfOurPreOrderSystem';
import EnsuringFoodSafety from '../components/PageComps/PreOrderPageComps/RefactoredPreOrderComps/EnsuringFoodSafety';
import HowToUseOurPreOrderingPlatform from '../components/PageComps/PreOrderPageComps/RefactoredPreOrderComps/HowToUseOurPreOrderingPlatform';
import PreOrderingPlatformForEvents from '../components/PageComps/PreOrderPageComps/RefactoredPreOrderComps/PreOrderingPlatformForEvents';

const PreOrderingPage = ({ data }) => {
  const { sanityPreOrderingPage } = data;
  const {
    pageHead,
    preOrderingPlatformForEvents,
    imagesAndParagraphs,
    howToUseOurPreOrderSystem,
    benefitsOfOurPreOrderingSystem,
    benefitCards,
    ensuringFoodSafety,
    preOrderingAllergenAndDietarySelection,
  } = sanityPreOrderingPage;
  const { title, _rawSubTitle: subTitle, background } = pageHead;
  const { asset } = background;

  return (
    <>
      <FullImageBackgroundHeader
        backgroundPosition="0% 50%"
        imageData={asset.gatsbyImageData}
        shadow="0.55"
        content={
          <BasicTitleSubTitle
            title={title}
            subTitle={subTitle}
            titleRowSpacing={30}
            titlePaddingBottom={undefined}
            largeSubTitleSize
          />
        }
      />
      <PreOrderingPlatformForEvents
        preOrderingPlatformForEvents={preOrderingPlatformForEvents}
        imagesAndParagraphs={imagesAndParagraphs}
      />
      <HowToUseOurPreOrderingPlatform
        howToUseOurPreOrderSystem={howToUseOurPreOrderSystem}
      />
      <AllergenAndDiningSelection
        preOrderingAllergenAndDietarySelection={
          preOrderingAllergenAndDietarySelection
        }
      />
      <EnsuringFoodSafety ensuringFoodSafety={ensuringFoodSafety} />
      <BenefitsOfOurPreOrderSystem
        benefitsOfOurPreOrderingSystem={benefitsOfOurPreOrderingSystem}
        benefitCards={benefitCards}
      />

      <FanGoBody paddingTop="0px" paddingBottom="20px">
        <TrustedBrandIcons />
      </FanGoBody>

      <BlogsPreview
        data={{
          ...data.sanityHomePage.blogsPreview,
          blogs: data.allSanityPost.nodes,
        }}
      />
    </>
  );
};

export default PreOrderingPage;

export const Head = ({ data }) => (
  <StandardSEO SEOData={data.sanityPreOrderingPage.seo} />
);

export const query = graphql`
  query ($language: String!) {
    sanityPreOrderingPage(language: { eq: $language }) {
      seo {
        title
        description
        keywords
        name
        type
      }
      pageHead {
        title
        _rawSubTitle
        background {
          asset {
            gatsbyImageData(placeholder: BLURRED, height: 1000, formats: [WEBP])
          }
        }
      }
      preOrderingAllergenAndDietarySelection {
        titleAndSubTitle {
          title
          text
        }
        _rawParagraph
      }
      howToUseOurPreOrderSystem {
        titleAndSubTitle {
          title
          text
        }
        image {
          asset {
            gatsbyImageData(placeholder: BLURRED, height: 600, formats: [WEBP])
          }
        }
        _rawParagraph
      }
      preOrderingPlatformForEvents {
        title
        _rawBody
      }
      imagesAndParagraphs {
        image {
          asset {
            gatsbyImageData(placeholder: BLURRED, height: 600, formats: [WEBP])
          }
        }
        paragraphs {
          title
          text
        }
      }
      benefitsOfOurPreOrderingSystem {
        title
        _rawBody
      }
      ensuringFoodSafety {
        title
        imagesAndParagraphs {
          image {
            asset {
              gatsbyImageData(
                placeholder: BLURRED
                height: 600
                formats: [WEBP]
              )
            }
          }
          paragraphs {
            title
            text
          }
        }
      }
      benefitCards {
        productFeatures {
          cardImage {
            asset {
              gatsbyImageData
            }
          }
          feature
          description
        }
      }
    }
    sanityHomePage(language: { eq: $language }) {
      blogsPreview {
        title
        buttonName
      }
    }
    allSanityPost(
      sort: { publishedAt: DESC }
      filter: { language: { eq: $language } }
    ) {
      nodes {
        title
        slug {
          current
        }
        publishedAt
        author
        excerpt
        mainImage {
          asset {
            gatsbyImageData(placeholder: BLURRED, width: 600, formats: [WEBP])
          }
          altText
        }
        category
        paragraphs {
          title
          body: _rawBody
          image {
            asset {
              altText
              url
            }
          }
        }
      }
    }
  }
`;
