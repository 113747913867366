import React, { useContext, useState } from 'react';

import { Grid, Theme, useMediaQuery } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

import { MobileScreenContext } from '../../../../providers/MobileScreenProvider';
import FanGoBody from '../../../common/body/FanGoBody';
import { FanGoTextProcessor } from '../../../common/portableText';
import FanGoTitle from '../../../common/texts/FanGoTitle';
import MobileScreen from '../../CommonComps/deviceScreens/MobileScreen';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    phoneWrapper: {
      [theme.breakpoints.up('sm')]: {
        animation: '$wiggle 5s infinite',
      },

      '&:hover': {
        animation: 'none',
      },
    },
    '@keyframes wiggle': {
      '0%': {
        transform: 'rotate(0deg)',
      },
      '80%': {
        transform: 'rotate(0deg)',
      },
      '85%': {
        transform: 'rotate(3deg)',
      },
      '95%': {
        transform: 'rotate(-3deg)',
      },
      '100%': {
        transform: 'rotate(0deg)',
      },
    },
    mobileContainer: {
      display: 'flex',
      justifyContent: 'center',
      [theme.breakpoints.up('sm')]: {
        paddingLeft: 10,
      },
      [theme.breakpoints.up('md')]: {
        paddingLeft: 50,
      },
      [theme.breakpoints.down('sm')]: {
        paddingTop: 15,
      },
    },
  })
);

const AllergenAndDiningSelection = ({
  preOrderingAllergenAndDietarySelection,
}) => {
  const classes = useStyles();

  const { titleAndSubTitle, _rawParagraph } =
    preOrderingAllergenAndDietarySelection;
  const { title } = titleAndSubTitle;

  const mobileView = useMediaQuery(`(max-width: 600px)`);
  const { animationPlayStatus } = useContext(MobileScreenContext);

  return (
    <FanGoBody alternate>
      <FanGoTitle title={title} />
      <Grid container sx={{ display: 'flex', justifyContent: 'center' }}>
        <Grid item xs={12} sm={6} md={5}>
          <FanGoTextProcessor
            text={_rawParagraph}
            textAlign={mobileView ? 'center' : 'left'}
          />
        </Grid>
        <Grid className={classes.mobileContainer} item xs={12} sm={6} md={4}>
          <div
            className={classes.phoneWrapper}
            style={{ animationPlayState: animationPlayStatus }}
          >
            <MobileScreen hideHome />
          </div>
        </Grid>
      </Grid>
    </FanGoBody>
  );
};

export default AllergenAndDiningSelection;
