import React from 'react';

import { useMediaQuery } from '@mui/material';

import FanGoBody from '../../../common/body/FanGoBody';
import { FanGoTextProcessor } from '../../../common/portableText';
import FanGoTitle from '../../../common/texts/FanGoTitle';
import ReverseImageAndText from '../../CommonComps/ReverseImageAndText';

const PreOrderingPlatformForEvents = ({
  preOrderingPlatformForEvents,
  imagesAndParagraphs,
}) => {
  const smallScreen = useMediaQuery(`(max-width: 900px)`);
  const {
    title: preOrderingPlatformForEventsTitle,
    _rawBody: preOrderingPlatformforEventsBody,
  } = preOrderingPlatformForEvents;
  return (
    <FanGoBody>
      <FanGoTitle title={preOrderingPlatformForEventsTitle} />
      <FanGoTextProcessor
        text={preOrderingPlatformforEventsBody}
        textAlign="center"
      />
      <div style={{ marginTop: 20 }}>
        <ReverseImageAndText
          imagesAndParagraphs={imagesAndParagraphs}
          smallScreen={smallScreen}
          imgGridItemSize={6}
          textGridItemSize={6}
          maxHeight={400}
          shadow={false}
        />
      </div>
    </FanGoBody>
  );
};

export default PreOrderingPlatformForEvents;
