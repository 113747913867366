import React from 'react';
import { v4 as uuidv4 } from 'uuid';

import DietaryAndAllergensScreen from './diningSelections/DietaryAndAllergensScreen';
import DiningSelectionsScreenContents from './diningSelections/DiningSelectionsScreenContents';
import DiningSelectionSubmission from './diningSelections/DiningSelectionSubmission';
import MobileHomeScreen from './MobileHomeScreen';
import TicketingScreen from './TicketingScreen';
import WeddingScreen from './WeddingScreen';

const getMobileScreen = ({ type }) => {
  let screenContentComponent = null;
  let screenTitle = '';
  let screenId = '';
  const uid = uuidv4();
  switch (type) {
    case 'ticketing':
      screenContentComponent = <TicketingScreen />;
      screenTitle = 'Ticketing';
      screenId = 'ticketing';
      break;
    case 'diningSelection':
      screenContentComponent = <DiningSelectionsScreenContents />;
      screenTitle = 'Dining Selection';
      screenId = 'diningSelection';
      break;
    case 'allergenAndDietarySelections':
      screenContentComponent = <DietaryAndAllergensScreen />;
      screenTitle = 'Allergen and Dietary Selection';
      screenId = 'allergenAndDietarySelections';
      break;
    case 'diningSelectionSubmission':
      screenContentComponent = <DiningSelectionSubmission />;
      screenTitle = 'Dining Selections Submission';
      screenId = 'diningSelectionSubmission';
      break;
    case 'wedding':
      screenContentComponent = <WeddingScreen />;
      screenTitle = 'Weddings';
      screenId = 'wedding';
      break;
    default:
      screenContentComponent = <MobileHomeScreen />;
      screenTitle = 'Home';
      screenId = 'home';
      break;
  }

  return { screenTitle, screenContentComponent, screenId, uid };
};

export default getMobileScreen;
