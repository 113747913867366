import getMobileScreen from './getMobileScreen';

export const mobileNavigation = ({
  screenStack,
  type,
  setScreen,
  screen,
  setNavigationType,
}) => {
  const { screenId } = screen;
  if (screenId === 'home' && type === 'home') {
    return;
  }

  // Only get a new version of the screen if you're navigating to a different screen from the one currently at the top of the stack.
  // E.g. A -> B -> C -> B - is okay to get a new screen
  // NOT A -> B -> A -> B - in this scenario get screen B, don't make a new version of B

  let screenToNavigateTo = getMobileScreen({ type });
  if (screenStack.length > 1) {
    const lastScreenAdded = screenStack[screenStack.length - 1];
    const { screenId } = lastScreenAdded;
    if (screenId === type) {
      screenToNavigateTo = lastScreenAdded;
    }
  }

  if (screenStack.length >= 1) {
    const lastScreenAdded = screenStack[screenStack.length - 1];
    const { screenId: lastScreenAddedScreenId } = lastScreenAdded;

    if (screenToNavigateTo.screenId !== lastScreenAddedScreenId) {
      screenStack.push(screenToNavigateTo);
    }
  }
  setNavigationType('forward');
  setScreen({ ...screenToNavigateTo });
};

export const navigateBackwards = ({
  screenStack,
  setScreen,
  screen,
  setNavigationType,
}) => {
  let indexOfCurrentScreen = screenStack.findIndex(
    (element) => element.uid === screen.uid
  );

  const previousScreen = screenStack[indexOfCurrentScreen - 1];
  setNavigationType('backwards');
  setScreen({ ...previousScreen });
};

export const navigateForwards = ({
  screenStack,
  screenId,
  setScreen,
  screen,
  setNavigationType,
}) => {
  let indexOfCurrentScreen = screenStack.findIndex(
    (element) => element.uid === screen.uid
  );
  const nextScreen = screenStack[indexOfCurrentScreen + 1];
  setNavigationType('forward');
  setScreen({ ...nextScreen });
};

export const diningSelectionOptions = [];

export const diningSelectionSections = [
  {
    sectionId: 'attendeeDetails',
    title: 'Attendee Details',
    options: [
      {
        label: 'Title',
        optionId: 'title',
        inputOptions: [
          { value: 'Miss', label: 'Miss' },
          { value: 'Ms', label: 'Ms' },
          { value: 'Mrs', label: 'Mrs' },
          { value: 'Mr', label: 'Mr' },
        ],
        type: 'select',
      },
      {
        label: 'Full Name',
        optionId: 'fullName',
        type: 'text',
      },
    ],
  },
  {
    title: 'Starter',
    sectionId: 'starter',
    options: [
      {
        label: 'Starter Selection',
        optionId: 'starterSelection',
        inputOptions: [
          {
            value: 'Butternut Squash Soup',
            label: 'Butternut Squash Soup',
            info: '(V)',
          },
          { value: 'Potted Smoked Salmon', label: 'Potted Smoked Salmon' },
          { value: 'Prawn Cocktail', label: 'Prawn Cocktail' },
        ],
        type: 'select',
      },
    ],
  },
  {
    title: 'Main Course',
    sectionId: 'main',
    options: [
      {
        optionId: 'mainSelection',
        label: 'Main Course Selection',
        inputOptions: [
          { value: 'Roast Beef', label: 'Roast Beef' },
          { value: 'Sirloin Steak', label: 'Sirloin Steak' },
          { value: 'Grilled Sea Bream', label: 'Grilled Sea Bream' },
        ],
        type: 'select',
      },
    ],
  },
  {
    title: 'Dessert',
    sectionId: 'dessert',
    options: [
      {
        optionId: 'dessertSelection',
        label: 'Dessert Selection',
        inputOptions: [
          {
            value: 'Crème Brûlée',
            label: 'Crème Brûlée',
            info: '(GF)',
            dietaryAndAllergens: { id: 'dairy' },
          },
          {
            value: 'Ice Cream',
            label: 'Ice Cream',
            dietaryAndAllergens: { id: 'dairy' },
          },
          {
            value: 'Yorkshire Cheese Selection',
            label: 'Yorkshire Cheese Selection',
            dietaryAndAllergens: { id: 'dairy' },
          },
        ],
        type: 'select',
      },
    ],
  },
];

export const getInitialValues = ({ type }) => {
  let initialValues = {};
  if (type === 'diningSelection') {
    initialValues['diningSelection'] = {
      attendeeDetails: {
        id: 'attendeeDetails',
        label: 'Attendee Details',
        title: 'Miss',
        fullName: 'Jane Doe',
      },
      starter: { starterSelection: 'Butternut Squash Soup' },
      main: { mainSelection: 'Roast Beef' },
      dessert: { dessertSelection: 'Crème Brûlée' },
      allergens: [],
      dietaries: [],
    };
  }
  return initialValues;
};

export const allergensAndDietaries = {
  allergenOptions: [
    { title: 'Celery', id: 'celery' },
    { title: 'Cereals containing gluten', id: 'cereal' },
    { title: 'Crustaceans', id: 'crustaceans' },
    { title: 'Eggs', id: 'eggs' },
    { title: 'Fish', id: 'fish' },
    { title: 'Lupin', id: 'lupin' },
    { title: 'Milk', id: 'milk' },
    { title: 'Molluscs', id: 'molluscs' },
    { title: 'Mustard', id: 'mustard' },
    { title: 'Nuts', id: 'nuts' },
    { title: 'Peanuts', id: 'peanuts' },
    { title: 'Sesame Seeds', id: 'sesameSeeds' },
    { title: 'Soya', id: 'soya' },
    { title: 'Sulphur Dioxide/Sulphites', id: 'sulphites' },
  ],

  dietaryOptions: [
    { title: 'Dairy Free', id: 'dairyFree' },
    { title: 'Dairy Adaptable', id: 'dairyAdaptable' },
    { title: 'Gluten Free', id: 'glutenFree' },
    { title: 'Gluten Adaptable', id: 'glutenAdaptable' },
    { title: 'Halal', id: 'halal' },
    { title: 'Halal Adaptable', id: 'halalAdaptable' },
    { title: 'Kosher', id: 'kosher' },
    { title: 'Pescetarian', id: 'pescetarian' },
    { title: 'Vegan', id: 'vegan' },
    { title: 'Vegan Adaptable', id: 'veganAdaptable' },
    { title: 'Vegetarian', id: 'vegetarian' },
    {
      title: 'Vegetarian Adaptable',
      id: 'vegetarianAdaptable',
    },
    { title: 'Contains Alcohol', id: 'containsAlcohol' },
    { title: 'Nut Free', id: 'nutFree' },
  ],
};
