import React, { useContext } from 'react';

import { Alert, Snackbar, Theme, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

import { MobileScreenContext } from '../../../../../providers/MobileScreenProvider';
import CustomButton from '../../../../common/button/CustomButton';
import FanGoFormField from '../../../../common/FanGoFormField';
import { allergensAndDietaries, navigateBackwards } from '../utils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      marginTop: 10,
      display: 'flex',
      flexDirection: 'column',
      rowGap: 15,
    },
    subContainer: {
      display: 'flex',
      flexDirection: 'column',
      rowGap: 4,
    },
    buttonWrapper: {
      display: 'flex',
      justifyContent: 'center',
    },
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      height: '85%',
      position: 'relative',
    },
  })
);

const DietaryAndAllergensScreen = () => {
  const {
    screen,
    setScreen,
    screenStack,
    setScreenStack,
    setNavigationType,
    setAnimationPlayStatus,
  } = useContext(MobileScreenContext);
  const classes = useStyles({ setAnimationPlayStatus });
  const { allergenOptions, dietaryOptions } = allergensAndDietaries;
  const [open, setOpen] = React.useState(false);

  const onClick = () => {
    setTimeout(() => {
      setOpen(true);
      setTimeout(() => {
        navigateBackwards({
          screenStack,
          setScreen,
          screen,
          setNavigationType,
        });
      }, 4000);
    }, 1000);
  };

  return (
    <div
      className={classes.wrapper}
      onMouseEnter={() => setAnimationPlayStatus('paused')}
      onMouseLeave={() => setAnimationPlayStatus('running')}
    >
      <div className={classes.container}>
        <div className={classes.subContainer}>
          <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
            Select Allergens
          </Typography>
          <FanGoFormField
            type="autoComplete"
            id="[diningSelection][allergens]"
            label="Allergens"
            options={allergenOptions}
          />
        </div>
        <div className={classes.subContainer}>
          <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
            Select Dietaries
          </Typography>
          <FanGoFormField
            type="autoComplete"
            id="[diningSelection][dietaries]"
            label="Dietaries"
            options={dietaryOptions}
          />
        </div>
      </div>
      <div className={classes.buttonWrapper}>
        <CustomButton onClick={onClick} buttonName="Submit" minWidth="100px" />
      </div>
      <Snackbar
        open={open}
        sx={{
          position: 'absolute',
          left: '0px!important ',
          bottom: '40px!important',
        }}
        autoHideDuration={5000}
        onClose={() => setOpen(false)}
      >
        <Alert onClose={() => setOpen(false)}>
          <p style={{ textAlign: 'center', padding: 0, margin: 0 }}>
            Allergen and Dietary Choices Successfully Submitted!
          </p>
        </Alert>
      </Snackbar>
    </div>
  );
};

export default DietaryAndAllergensScreen;
