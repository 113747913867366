import React, { useContext } from 'react';

import { MobileScreenContext } from '../../../../providers/MobileScreenProvider';

const TicketingScreen = () => {
  const { screen, setScreen, screenStack, setScreenStack } =
    useContext(MobileScreenContext);
  console.log(screenStack);
  return <div>TicketingScreen</div>;
};

export default TicketingScreen;
