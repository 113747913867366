import React, { useContext } from 'react';

import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FlatwareIcon from '@mui/icons-material/Flatware';
import { Button, Theme, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

import { MobileScreenContext } from '../../../../providers/MobileScreenProvider';
import { mobileNavigation } from './utils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: theme.palette.primary.main,
      borderRadius: 8,
      padding: theme.spacing(1),
      cursor: 'pointer',
      color: 'white',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      minHeight: '82px',
    },
    iconsContainer: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      columnGap: theme.spacing(2),
      rowGap: theme.spacing(2),
      marginTop: theme.spacing(2),
    },
  })
);

const icons = [
  { type: 'ticketing', icon: <ConfirmationNumberIcon />, title: 'Ticketing' },
  {
    type: 'diningSelection',
    icon: <FlatwareIcon />,
    title: 'Dining Selection',
  },
  {
    type: 'wedding',
    icon: <FavoriteIcon />,
    title: 'Wedding',
  },
];

const MobileHomeScreen = () => {
  const classes = useStyles();
  const { screen, setScreen, screenStack, setScreenStack, setNavigationType } =
    useContext(MobileScreenContext);
  return (
    <div className={classes.iconsContainer}>
      {icons.map((iconObj) => {
        const { type, icon, title } = iconObj;
        return (
          <div
            className={classes.container}
            onClick={() => {
              mobileNavigation({
                type: type,
                screenStack,
                setScreen,
                screen,
                setNavigationType,
              });
            }}
          >
            <Typography
              align="center"
              sx={{ fontWeight: 'bold' }}
              fontSize={'14px!important'}
            >
              {title}
            </Typography>
            {icon}
          </div>
        );
      })}
    </div>
  );
};

export default MobileHomeScreen;
