import { Formik } from 'formik';
import React, { useContext, useEffect } from 'react';

import { Theme, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

import { MobileScreenContext } from '../../../../providers/MobileScreenProvider';
import FanGoIconButton from '../../../common/button/FanGoIconButton';
import getMobileScreen from './getMobileScreen';
import {
  getInitialValues,
  mobileNavigation,
  navigateBackwards,
  navigateForwards,
} from './utils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    phoneCase: {
      display: 'flex',
      width: '100%',
      height: '580px',
      minWidth: '280px',
      maxWidth: '280px',
      boxShadow: '0 3px 15px rgb(0 0 0 / 0.3)',
      borderRadius: 20,
      backgroundColor: 'rgb(245, 245, 245, 0.9)',
    },
    screen: {
      margin: theme.spacing(1),
      // boxShadow: '0 3px 10px rgb(0 0 0 / 0.3)',
      width: '100%',
      borderRadius: 20,
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      paddingBottom: theme.spacing(0.5),
      paddingTop: theme.spacing(0.5),
      display: 'flex',
      alignItems: 'flex-end',
      backgroundColor: 'rgb(255, 255, 255, 0.9)',
    },
    screenContentsContainer: {
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    contents: {
      height: '100%',
      flex: 1,
      overflow: 'hidden',
    },
    buttonWrapper: {
      margin: 'auto',
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(1),
    },
    inputOptionsContainer: {
      display: 'flex',
      rowGap: theme.spacing(2),
      flexDirection: 'column',
      paddingLeft: '5px',
    },
    sectionTitleText: {
      color: '#141854',
      fontWeight: 'bold',
      marginBottom: theme.spacing(1.5),
      fontSize: '14px',
    },
    customTextField: {
      '& input::placeholder': {
        fontSize: '10px',
      },
    },
    input: {
      fontSize: '7px',
    },
    allergensContainer: {
      paddingLeft: '5px',
      display: 'flex',
      flexDirection: 'column',
      margin: 0,
    },
    footerNavContainer: {
      paddingLeft: '5px',
      paddingRight: '5px',
      paddingTop: '2px',
      paddingBottom: '2px',
      borderRadius: 10,
      // backgroundColor: theme.palette.primary.main,
      fontSize: '10px',
      display: 'flex',
      justifyContent: 'space-between',
    },
    headerNavContainer: {
      // backgroundColor: theme.palette.primary.main,
      // color: 'white',
      borderRadius: 10,
    },
  })
);

const MobileScreen = ({ top = 0, shadow = '', hideHome = false }) => {
  const {
    screen,
    setScreen,
    screenStack,
    setScreenStack,
    setNavigationType,
    navigationType,
  } = useContext(MobileScreenContext);
  const classes = useStyles(shadow);
  const initialScreen = getMobileScreen({ type: 'diningSelection' });
  useEffect(() => {
    setScreen({ ...initialScreen });
    setScreenStack([initialScreen]);
  }, []);

  const onSubmit = () => {};

  const { screenContentComponent, screenTitle, screenId, uid } = screen;
  const initialValues = getInitialValues({ type: 'diningSelection' });

  return (
    <Formik initialValues={{ ...initialValues }} onSubmit={onSubmit}>
      {({ handleChange, values }) => (
        <form>
          <div
            className={classes.phoneCase}
            style={{ position: 'relative', top: top }}
          >
            <div className={classes.screen}>
              <div className={classes.screenContentsContainer}>
                <div className={classes.contents}>
                  <Typography
                    variant="subtitle1"
                    textAlign="center"
                    sx={{ fontWeight: 'bold' }}
                  >
                    {screenTitle}
                  </Typography>
                  {screenContentComponent}
                  {/* {navigationType === 'forward' && (
                    <SlideContainer
                      navigationType={navigationType}
                      screenTitle={screenTitle}
                      key={screenId}
                    >
                      {screenContentComponent}
                    </SlideContainer>
                  )}
                  {navigationType === 'backwards' && (
                    <RenderScreenLayers
                      screenStack={screenStack}
                      screenToShow={screen}
                    />
                  )}

                  {!navigationType && <>{screenContentComponent}</>} */}
                </div>
                <div className={classes.footerNavContainer}>
                  <FanGoIconButton
                    type="backward"
                    size="small"
                    onClick={() =>
                      navigateBackwards({
                        screenStack,
                        setScreen,
                        screen,
                        setNavigationType,
                      })
                    }
                    disabled={
                      screenStack.length === 1 ||
                      (!!screenStack.length && screenStack[0].uid === uid)
                    }
                  />
                  {!hideHome && (
                    <FanGoIconButton
                      type="home"
                      size="small"
                      onClick={() => {
                        mobileNavigation({
                          type: 'home',
                          screenStack,
                          setScreen,
                          screen,
                          setNavigationType,
                        });
                      }}
                    />
                  )}
                  <FanGoIconButton
                    type="forward"
                    size="small"
                    onClick={() => {
                      navigateForwards({
                        screenId,
                        screenStack,
                        setScreen,
                        screen,
                        setNavigationType,
                      });
                    }}
                    disabled={
                      screenStack.length === 1 ||
                      (!!screenStack.length &&
                        screenStack[screenStack.length - 1].screenId ===
                          screenId)
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </form>
      )}
    </Formik>
  );
};

export default MobileScreen;
